import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import { translate } from "@/core/plugins/i18n";
import OwnerPaginationRequest from "@/models/general/OwnerPaginationRequest";
import PaginationResponse from "@/models/general/PaginationResponse";
import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import NewProductEntry from "@/models/products/productEntry/NewProductEntry";
import ProductEntryInfo from "@/models/products/productEntry/ProductEntryInfo";
import ProductEntryRow from "@/models/products/productEntry/ProductEntryRow";
import { AxiosResponse } from "axios";
import ApiService from "../ApiService";
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from "../SwalMessageService";
import ProductEntryTableService from "./interfaces/ProductEntryTableService";

export default class ProductEntryService extends GenericCrudService<NewProductEntry, ProductEntryInfo> implements ProductEntryTableService {

    constructor(){
       super("/ProductLiquidation", new SwalMessageService() );
    }
    
    async getTableProductEntry(paginator: OwnerPaginationRequest<GeneralFiltersTable>): Promise<AxiosResponse<PaginationResponse<ProductEntryRow>>> {
        return  ApiService.get<PaginationResponse<ProductEntryRow>>(this.apiController, 
            `GetPagedList?`
            + `CurrentPage=${paginator.currentPage}`
            + `&PageSize=${paginator.pageSize}`
            + (paginator.filters.filterName != '' ? `&Criteria.Name=${paginator.filters.filterName}` : '')
            + `&Criteria.Active=${paginator.filters.filterActive}`
            + `&Criteria.LiquidationId=${paginator.ownerId}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    /**
     * Cierra la entrada deseada
     * @param id identificador de la entrada
     * @returns indicador
     */
     async closedEntry(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(
            this.apiController
            + `/${id}/closed`
            , {}
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
    /**
     * Activa la entrada deseada
     * @param id identificador de la entrada
     * @returns indicador
     */
    async activeEntry(id: string): Promise<AxiosResponse<boolean>>{
        return  ApiService.put(this.apiController + `/${id}/activate`
            , id
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    } 
}