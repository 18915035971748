/**
 * Tipos de comportamiento de un formulario
 */
export enum SalesTypes {
    UpdateSale = 1
    , CreateSale = 2
    , ReadSale = 3
    , UpdateQuote = 4
    , CreateQuote = 6
    , ReadQuote = 7
}