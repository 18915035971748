import Modules from '@/store/enums/Modules';
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import { Identifiable } from "@/models/general/Identifiable";
import ExtraInfoForm from "@/models/products/ExtraInfoForm";
import NewProductEntry from "@/models/products/productEntry/NewProductEntry";
import NewProductEntryRequest from "@/models/products/productEntry/NewProductEntryRequest";
import ProductEntryInfo from "@/models/products/productEntry/ProductEntryInfo";
import ProductInfoOption from "@/models/products/ProductInfoOption";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import NewProductEntryState from "../../models/NewProductEntryState";
import { Actions } from "./Actions";
import { Mutations } from "./Mutations";
import { createRequest } from "@/store/functions/OwnerSettlementRequest";
import ProductEntryService from "@/core/services/products/ProductEntryService";
import { realoadTableOwnerSettlement } from "@/store/functions/GenericTable";
import ProductsService from '@/core/services/products/ProductsService';
import OptionSelect from '@/models/shared/OptionSelect';

@Module({dynamic: true, store, namespaced: true, name: Modules.NewProductEntry})
export default class NewProductEntryModule extends VuexModule implements NewProductEntryState {
    selects = { 
        productOptions: [] as ProductInfoOption[]
    };
    initialValues = formDefaultValues;
    loadings = { 
        productSelect: false 
    };
    loading = false;

    /**
     * Opciones de los productos con el formato del select
     */
     get productOptions(): OptionSelect[] {
        return this.selects.productOptions;
    }
    /**
     * Obtiene los valores iniciales del formulario
     */
    get getInitialValues(): Identifiable<string, NewProductEntry> {
        return this.initialValues;
    }
    /**
     * Información extra del producto
     */
     get productExtraInfo(): (productId: string) => ExtraInfoForm {
        return (productId: string) =>
            (this.selects
                .productOptions
                .find(product => product.id === productId) as ExtraInfoForm)
                ?? {
                    qualityName: ''
                    , sizeName: ''
                    , unitOfMeasurementName: ''
                };
    }

    @Mutation
    [Mutations.RESET_VALUES_ENTRY_FORM](){
        this.initialValues = formDefaultValues;
        this.selects.productOptions = []
    }

    @Mutation
    [Mutations.SET_DATA_ENTRY](values: Identifiable<string, ProductEntryInfo>){
        if(values && values.id != ''){
            this.selects.productOptions = [
                {
                    id: values.productId as string
                    , name: values.productName as string
                    , qualityName: ''// values.qualityName as string
                    , sizeName: ''//values.sizeName as string
                    , unitOfMeasurementName: values.unit as string
                    , unitOfMeasurementId: ''
                }
            ];

            this.initialValues = values;
        }
    }
    @Mutation
    [Mutations.SET_PRODUCTS](options: ProductInfoOption[]){
        this.selects.productOptions = options;
    }
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_LOADINGS](values: any){
        SaveValuesToStore(this.loadings, values);
    }

    @Action
    async [Actions.SAVE_NEW_ENTRY](form: NewProductEntry){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        const request = createRequest<NewProductEntryRequest>(this.context, form)
        return service.save(request)
            .then((resp) => {
                if(resp.data){
                    realoadTableOwnerSettlement(this.context, Modules.TableProductEntry)
                } 
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                this.context.commit(Mutations.RESET_VALUES_ENTRY_FORM)
            })
    }
    @Action
    async [Actions.UPDATE_ENTRY](form: NewProductEntry){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        const request = createRequest<NewProductEntryRequest>(this.context, form);
        const idEntry = (this.context.state as NewProductEntryState).initialValues.id;
        return service.update(idEntry, request)
            .then((resp) => {
                if(resp.data){
                    realoadTableOwnerSettlement(this.context, Modules.TableProductEntry)
                } 
                return resp.data;
            })
            .finally( () => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
                this.context.commit(Mutations.RESET_VALUES_ENTRY_FORM)
            });
    }

    @Action({ commit: Mutations.SET_PRODUCTS })
    async [Actions.SEARCH_PRODUCTS](filterName: string){
        this.context.commit(Mutations.SET_LOADINGS, { productSelect: true });
        return (await serviceProducts.searchProducts(filterName)
            .finally( () => this.context.commit(Mutations.SET_LOADINGS, {productSelect: false}))).data ?? []
    }

    @Action({ commit: Mutations.SET_DATA_ENTRY })
    async [Actions.SEARCH_DATA_ENTRY](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? formDefaultValues
    }
}

const service =  new ProductEntryService();
const serviceProducts = new ProductsService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    id: ''
    , amount: 0
    , productId: ''
} as Identifiable<string, NewProductEntry>
