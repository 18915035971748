
import { computed, defineComponent, reactive, ref } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { useRouter } from "vue-router";

export default defineComponent({
    components:{
        ModuleMaster
        , Datatable
        , Datarow
    },
    setup(){
        const showSearchProduct = ref(false);
        const showExtras = ref(false);
        const router = useRouter();

         /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "date", HeadLabel: "Fecha", Type: "text", VisibleInGrid: true },
        ]);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Pedido",
            titleNewCrudAction: "Agrega Pedido",
        });
       
       //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable([
            {
                folio: "12345",
                date: "01-05-2022",
            },
             {
                folio: "12341",
                date: "01-05-2022",
            },
             {
                folio: "12342",
                date: "01-05-2022",
            },
             {
                folio: "12344",
                date: "01-05-2022",
            }
            ]/*moduleTable.records*/, configTable, "status");
        });

        const handleCrudAction = async (action: string | number = "N", id: string) => {
            if(action == "N"){
             router.push({
                name: "/sales/newsale"
            });
            }else{
               router.push({
                name: "/sales/detailSales"
            });
            }
        }
        const totalRecords = computed(() => 12/*moduleTable.totalRecords*/);
        const sizePage = computed(() => 5/*moduleTable.pagination.pageSize*/);
        
         return {
            headers
            , renderRows
            , labels
            , totalRecords
            , sizePage
            , showSearchProduct
            , showExtras
            , handleCrudAction
         }
    }
})
