
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import {computed, defineComponent, ref, watch } from "vue"
import { hideModalById, listenerHideModal } from "@/core/helpers/dom";
import { getModule } from 'vuex-module-decorators'
import {  Form, Field } from "vee-validate";
import * as Validations from "yup";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import SaleDetail from "@/models/sales/SaleDetail";
import TaxesModule from "@/store/modules/taxes/modules/new-taxes";
import Select from "@/components/forms/Select.vue"
import { useStore } from "vuex";

// import Select from "@/components/forms/Select.vue"

export default defineComponent({
    components:{
        DynamicContentModal,
        Form,
        Field,
        Select
    },
    setup() {
        //Propiedades
        const store = useStore(); 
        const moduleNewSale = getModule(NewSaleModule);
        const formLiquidation = ref();
        const moduleTaxes = getModule(TaxesModule);
        const haveDiscont = ref(true);
        const porcentDiscont = ref<any>([
        ]);

        const infoConcept = ref<SaleDetail>({
            productId: "",
            code: "",
            productName: "",
            unitOfMeasurementName: "",
            saleId: "",
            unitOfMeasurementId: "",
            discountPersent: 0,
            taxPersent: 0,
            price: 0,
            amount: 0,
            discount: 0,
            tax: 0,
            total: 0
            , unit: ''
            ,import: 0
            ,subTotal: 0
            ,status: true
            ,buyerPrice:0,
            maximunDiscount:0,
            minimunDiscount: 0,
            publicPrice: 0,
            wholesalePrice: 0,
            isInventory: false,
            stock: 0
        })

        let validationSchema = Validations.object().shape({
            amount: Validations.number().min(0, 'Valor minimo 0').typeError('Ingrese una cantidad valida').required().label("amount"),
            price: Validations
                    .number()
                    .required()
                    .min(1, 'Precio minimo 1.')
                    .typeError('Ingrese una cantidad valida')
                    .label("price"),
            dto:  Validations
                .number()
                .min(0, `Minimo desuento 0`)
                .max(100, `Maximo descuento 100`)
                .typeError('Ingrese una cantidad valida')
                .label("dto")
        });

        const formatNumeric = (number) => {
          var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2
          });
          
          return formatter.format(number);
        }

        const calculatePercentage = (price: number, amount: number, percentage: number) => {
            let result = 0;
            result = ((amount * price) * percentage) / 100;
            return result;
        }

        const submitForm = (data: any) => {
            moduleNewSale.DELETE_PRODUCT(infoConcept.value.productId);
            const discontPersent = calculatePercentage(data.price, data.amount, data?.dto ?? 0 )

            const taxRow = parseFloat((((data.amount * data.price) - (discontPersent)) * (infoConcept.value.taxPersent / 100)).toFixed(2));
            console.log(taxRow);
            data.productId = infoConcept.value.productId;
            data.productName = infoConcept.value.productName;
            data.unitOfMeasurementName = infoConcept.value.unitOfMeasurementName;
            data.discount =  parseFloat((discontPersent).toFixed(2)),
            data.import =  parseFloat(((data.amount * data.price) - discontPersent).toFixed(2)),
            data.subTotal = parseFloat(((data.amount * data.price) - (discontPersent)).toFixed(2)),
            data.tax = taxRow,//parseFloat(((data.subTotal - discontPersent) * (infoConcept.value.taxPersent / 100)).toFixed(2));
            data.total =   parseFloat(((data.amount * data.price) - (discontPersent)).toFixed(2)) + taxRow; 
            // data.price = data.price;
            data.unitOfMeasurementId = infoConcept.value.unitOfMeasurementId;
            data.code = infoConcept.value.code;
            data.taxPersent = infoConcept.value.taxPersent;
            data.discountPersent = data?.dto ?? 0;
            data.status = true;
            data.id = infoConcept.value.id;
            data.saleId = infoConcept.value.saleId;

            debugger

            moduleNewSale.UPDATE_PRODUCT(data);
            hideModalById("#EditProductForm");

            // haveDiscont.value = true;
            porcentDiscont.value = [];
        };

        const taxesInfo = computed(() => moduleTaxes.getTaxeInfo);
    
        watch(() => moduleNewSale.getSalectProduct, async (currentValue: SaleDetail) => {
                porcentDiscont.value = [];
                infoConcept.value = currentValue;

                porcentDiscont.value.push({
                    id:  infoConcept.value.publicPrice,
                    name: infoConcept.value.publicPrice.toFixed(2)
                });

                porcentDiscont.value.push({
                    id: infoConcept.value.wholesalePrice, //productInfo.value.wholesalePrice > 0 ? 1 : 0,
                    name: infoConcept.value.wholesalePrice.toFixed(2)
                });

                porcentDiscont.value.push({
                    id: infoConcept.value.buyerPrice,//productInfo.value.buyerPrice > 0 ? 2 : 0,
                    name: infoConcept.value.buyerPrice.toFixed(2)
                });

                // if(infoConcept.value.minimunDiscount > 0){
                //     for (let i = infoConcept.value.minimunDiscount; i <= infoConcept.value.maximunDiscount; i++) {
                //         porcentDiscont.value.push({
                //         id: i,
                //         name: `${i } %`
                //         });
                //     }
                //     haveDiscont.value = false;
                // }

                if(canSalePriceFree.value){
                    validationSchema.fields.dto =  Validations
                                             .number()
                                             .min(0, `Minimo desuento 0`)
                                             .max(100, `Maximo descuento 100`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                    haveDiscont.value = false;
                }else{
                    if(infoConcept.value.maximunDiscount > 0 && !canSalePriceFree.value){
                            validationSchema.fields.dto = Validations
                                             .number()
                                             .min(infoConcept.value.minimunDiscount, `Minimo desuento ${infoConcept.value.minimunDiscount}`)
                                             .max(infoConcept.value.maximunDiscount, `Maximo descuento ${infoConcept.value.maximunDiscount}`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                            haveDiscont.value = false;
                        }

                        if(infoConcept.value.maximunDiscount > 0){
                            haveDiscont.value = false;
                        } 
                }

                // if(infoConcept.value.minimunDiscount > 0){
                //     haveDiscont.value = false;
                // }

                validationSchema.fields.amount = Validations
                                                    .number()
                                                    .required()
                                                    .min(1, 'Ingrese una cantidad minima de 1')
                                                    .max(infoConcept.value.stock, `Ingrese una cantidad minima de ${infoConcept.value.stock}`)
                                                    .typeError('Ingrese una cantidad valida')
                                                    .label("amount");


                formLiquidation.value.setValues({
                   amount: currentValue.amount,
                   price: parseFloat((currentValue.price).toFixed(2)),
                   dto: currentValue.discountPersent
                });

                if(!infoConcept.value.isInventory){
                     validationSchema.fields.amount = Validations
                                             .number()
                                            .required()
                                             .min(1, 'Ingrese una cantidad minima de 1')
                                             .typeError('Ingrese una cantidad valida')
                                             .label("amount");
                 }

        });
        
        const resetForm = () => {
            moduleNewSale.RESET_EDIT_PRODUCT();
            formLiquidation.value.resetForm();
            validationSchema.fields.dto = Validations
                .number()
                .min(0, `Minimo desuento 0`)
                .max(100, `Maximo descuento 100`)
                .typeError('Ingrese una cantidad valida')
                .label("dto")
            haveDiscont.value = true;
            formLiquidation.value.setFieldValue('dto',  0);
        }
        const canSalePriceFree = computed(() => store.getters['canSalePriceFree']);


        return {
            canSalePriceFree,
            formLiquidation,
            validationSchema,
            infoConcept,
            porcentDiscont,
            haveDiscont,
            store,
            calculatePercentage,

            formatNumeric,

            submitForm,
            resetForm
        }

    },
    methods:{
        closeListener(){
            this.resetForm();
        },
        triggerHidden: function(){
            listenerHideModal("#EditProductForm", this.closeListener);
        },
    },
    mounted(){
        this.triggerHidden();
    }
})
