
import { computed, defineComponent, reactive, ref } from "vue";
// import Controlls from "./ControllsAddedProduct.vue"
import {  Form, Field } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import * as Validations from "yup";
import Select from "@/components/forms/Select.vue"
import axios, { AxiosResponse } from 'axios';
import ApiService from '@/core/services/ApiService';
import PaginationResponse from "@/models/general/PaginationResponse";
import ProductList from "@/models/products/ProductsList";
import { getMessageError } from "@/core/helpers/messageFromPulmeros";
import Paginator from "@/components/c-paginator/Paginator.vue";
import { getModule } from "vuex-module-decorators";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import SaleDetail from "@/models/sales/SaleDetail";
import TaxesModule from "@/store/modules/taxes/modules/new-taxes";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { UserTypes } from "@/core/enums/user-types";
import OptionSelect from "@/models/shared/OptionSelect";
import { useStore } from "vuex";

export default defineComponent({
    components:{
        // Controlls
        Form,
        Field,
        Select,
        Paginator,
        SelectFilterRemote
    },
    setup(){
        let expandCollapse = ref(true);
        let numItemsPaginator = ref(0);
        let itemsPerPage = ref(5);
        let currentPage = ref(1);
        let page = ref(1);
        let isSelectedProduct = ref(false);
        let productSelected = ref<ProductList | undefined>();
        let records = ref<ProductList[]>([]);
        let dataSearch = ref<any>();
        const store = useStore(); 
        const moduleNewSale = getModule(NewSaleModule);
        const moduleTaxes = getModule(TaxesModule);
        const haveDiscont = ref(true);
        const warehouseCombo = ref([]);
        const familyCombo = ref([]);
        const formAddProductToSale = ref();
        const subfamilyCombo = ref([]);
        const porcentDiscont = ref<any>([
        ]);

        const validationShearchSchema  =  Validations.object().shape({
              name: Validations.string().nullable().default('').label("name"),
              code: Validations.string().nullable().default('').label("code"),
              description: Validations.string().nullable().default('').label("description"),
              warehouseId: Validations.string().nullable().default('').label("warehouseId"),
              familyId: Validations.string().nullable().default('').label("familyId"),
              subfamilyId: Validations.string().nullable().default('').label("subfamilyId")
        });

        const validationAddProductSchema = Validations.object().shape({
                amount: Validations
                        .number()
                        .required()
                        .min(1, 'Ingrese una cantidad minima de 1')
                        .typeError('Ingrese una cantidad valida')
                        .label("amount"),
                price: Validations
                        .number()
                        .required()
                        .typeError('Ingrese una cantidad valida')
                        .label("price"),
                dto: Validations
                    .number()
                    .min(0, `Minimo desuento 0`)
                    .max(100, `Maximo descuento 100`)
                    .typeError('Ingrese una cantidad valida')
                    .label("dto")
        });

        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Agregarlos al cliente",
        });
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "code", HeadLabel: "Código", Type: "text", VisibleInGrid: true },
            { PropName: "name", HeadLabel: "Nombre", Type: "text", VisibleInGrid: true },
            { PropName: "stock", HeadLabel: "Almacén", Type: "text", VisibleInGrid: true },
            { PropName: "existence", HeadLabel: "Existencia", Type: "text", VisibleInGrid: true },            
        ]);
        
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const handleSearchWarehouse = (query) => {
            let params = ``;

            // if(query != undefined && query != ''){
            //     params += `?criteria=${query}`;
            // }
        
            return ApiService.get<OptionSelect[]>('/Warehouses', 
                `combo${params}`
            )
            .then(resp => {
                console.log(resp.data);
                warehouseCombo.value = resp.data;
                return resp;
            }).catch(error => {
                return { data: null} as AxiosResponse;
            });
        }

        const handlesubfamilia = (id) => {
            return ApiService.get<OptionSelect[]>('/SubFamilies', 
                `${id}/combo`
            )
            .then(resp => {
                subfamilyCombo.value = resp.data;
                return resp;
            }).catch(error => {
                return { data: null} as AxiosResponse;
            });
        }

        const handleFamily = (query) => {
            let params = ``;

            // if(query != undefined && query != ''){
            //     params += `?criteria=${query}`;
            // }
        
            return ApiService.get<OptionSelect[]>('/Families', 
                `combo${params}`
            )
            .then(resp => {
                familyCombo.value = resp.data;
                return resp;
            }).catch(error => {
                return { data: null} as AxiosResponse;
            });
        }

        const searchForm = (data: any) => {
            console.log(data)
            let params = `CurrentPage=${currentPage.value}&PageSize=5`;

            if(data.name != undefined && data.name != ''){
                params += `&Criteria.Name=${data.name}`;
            }

            if(data.description != undefined && data.description != ''){
                params += `&Criteria.Description=${data.description}`;
            }

            if(data.warehouseId != undefined && data.warehouseId != ''){
                params += `&Criteria.WarehouseId=${data.warehouseId}`;
            }

            if(data.familyId != undefined && data.familyId != ''){
                params += `&Criteria.familyId=${data.familyId}`;
            }

            if(data.subfamilyId != undefined && data.subfamilyId != ''){
                params += `&Criteria.subfamilyId=${data.subfamilyId}`;
            }

            if(data.code != undefined && data.code != ''){
                params += `&Criteria.Code=${data.code}`;
            }
        
            return ApiService.get<PaginationResponse<ProductList[]>>('/Products', 
                `GetPagedList?${params}`
            )
            .then(resp => {
                records.value = resp.data.data;
                numItemsPaginator.value = resp.data.totalRecords;
                itemsPerPage.value = 5;
                currentPage.value = resp.data.currentPage;
                expandCollapse.value = false;
                dataSearch.value = data;
                return resp;
            }).catch(error => {
                // getMessageError(error, this.messageService);
                return { data: null} as AxiosResponse;
            });
        }

        const calculatePercentage = (price: number, amount: number, percentage: number) => {
            let result = 0;
            result = ((amount * price) * percentage) / 100;
            return result;
        }

         const sucessAddProduct = () => {
            
            Swal.fire({
                text: "El producto se agrego correctamente",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Aceptar",
                customClass: {
                confirmButton: "btn btn-primary",
                },
            }).then(() => {
                records.value.forEach(p => p.selected = false);
                isSelectedProduct.value = false;
                haveDiscont.value = true;
                porcentDiscont.value = [];
                console.log("ok");
            });
        }

        const submitForm = (data: any) => {
            const productSelected = records.value.find(d => d.selected == true);

            if(productSelected){
                // if(canSalePriceFree.value || productSelected.isInventory){
                //     productSelected.price = data.price; 
                // }else{
                //     switch(data.price){
                //         case UserTypes.Buyer:
                //             productSelected.price = parseFloat(productSelected.buyerPrice.toFixed(2));
                //             break;
                //         case UserTypes.Public:
                //             productSelected.price = parseFloat(productSelected.publicPrice.toFixed(2)); 
                //             break;
                //         case UserTypes.Wholesale:
                //             productSelected.price = parseFloat(productSelected.wholesalePrice.toFixed(2)); 
                //             break;
                //         default:
                //             // productInfo.value.price = productInfo.value.price; 
                //             break;
                //     }
                // }

                const index = moduleNewSale.getSaleDetailAll.find(d => d.productId === productSelected.id);
                
                const discontPersent = calculatePercentage( data.price, data.amount, data?.dto ?? 0 );
                let taxRow = parseFloat((((data.amount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2));
                const saleDetail = {
                    id: index ? index.id : '00000000-0000-0000-0000-000000000000',
                    productId: productSelected.id,
                    code: '',
                    productName: productSelected.name,
                    unitOfMeasurementName: productSelected.unitOfMeasurementName,
                    unitOfMeasurementId: productSelected.unitOfMeasurementId,
                    price: data.price,
                    amount: data.amount,
                    discount: parseFloat((discontPersent).toFixed(2)), //(precio del producto * cantidad)
                    saleId: index ? index.saleId : productSelected.id, 
                    total: (parseFloat(((data.amount * data.price) - (discontPersent)).toFixed(2)) + taxRow ), //(Cantidad *  Precio)
                    import: parseFloat(((data.amount * data.price) - (discontPersent)).toFixed(2)),
                    subTotal: parseFloat(((data.amount * data.price) - (discontPersent)).toFixed(2)),
                    tax: taxRow,//parseFloat((((data.amount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2)),  // tax --> (importe * (porcentaje de iva / 100)),
                    discountPersent: data?.dto ?? 0,
                    taxPersent: productSelected.taxValue,
                    wholesalePrice: productSelected.wholesalePrice,
                    publicPrice: productSelected.wholesalePrice,
                    buyerPrice: productSelected.buyerPrice,
                    maximunDiscount: productSelected.maximunDiscount,
                    minimunDiscount: productSelected.minimunDiscount 
                    , unit: ''
                    , status: index ? index.status : true
                } as SaleDetail;

                if(index){
                    if(index.status){
                       Swal.fire({
                        title: `Este producto se encuentra en la venta`,
                        text: `¿Desea agregar la cantidad al producto?`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#000000',
                        cancelButtonColor: '#7E829',
                        confirmButtonText: 'Si, agregar',
                        cancelButtonText: 'Cancelar'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            moduleNewSale.DELETE_PRODUCT(index.productId);
                            
                            const newAmount:number = parseInt(data.amount) + parseInt(index.amount.toString());
                            taxRow = parseFloat((((newAmount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2));
                            saleDetail.amount = newAmount;
                            saleDetail.discount = discontPersent;
                            saleDetail.discountPersent = data?.dto ?? 0;
                            saleDetail.total = parseFloat(((newAmount *data.price) - (discontPersent)).toFixed(2)) + taxRow;
                            saleDetail.subTotal = parseFloat(((newAmount *data.price) - (discontPersent)).toFixed(2));
                            saleDetail.import = parseFloat(((newAmount * data.price) - (discontPersent)).toFixed(2));
                            saleDetail.tax = taxRow, //parseFloat((((newAmount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2));
                            saleDetail.status = true;
                            
                            moduleNewSale.ADD_PRODUCT(saleDetail);

                            sucessAddProduct();
                            // productInfo.value = formDefaultValues;
                            // formAdd.value.resetForm();
                            // showMoreInfo.value = false;
                        }
                });
                    }else{
                        moduleNewSale.DELETE_PRODUCT(index.productId);
                        const newAmount:number = parseInt(data.amount);
                        taxRow = parseFloat((((newAmount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2));
                        saleDetail.amount = newAmount;
                        saleDetail.discount = discontPersent;
                        saleDetail.discountPersent = data?.dto ?? 0;
                        saleDetail.total = parseFloat(((newAmount * data.price) - (discontPersent)).toFixed(2)) + taxRow;
                        saleDetail.subTotal = parseFloat(((newAmount * data.price) - (discontPersent)).toFixed(2));
                        saleDetail.import = parseFloat(((newAmount * data.price) - (discontPersent)).toFixed(2));
                        saleDetail.tax = parseFloat((((newAmount * data.price) - (discontPersent)) * (productSelected.taxValue / 100)).toFixed(2));
                        saleDetail.status = true;
                        
                        moduleNewSale.ADD_PRODUCT(saleDetail);
                        
                        sucessAddProduct();
                        // productInfo.value = formDefaultValues;
                        // formAdd.value.resetForm();
                        // showMoreInfo.value = false;
                    }
                }else{
                    moduleNewSale.ADD_PRODUCT(saleDetail);  
                    sucessAddProduct();
                }
            }

            

        }

        const calculatePriceByInput = (userType: UserTypes = 0) => {
            let price = 0;
            
            const productSelected = records.value.find(d => d.selected == true);

            if(productSelected){
                switch(userType){
                    case UserTypes.Buyer:
                        price = parseFloat(productSelected?.buyerPrice.toFixed(2)); 
                        break;
                    case UserTypes.Public:
                        price = parseFloat(productSelected?.publicPrice.toFixed(2)); 
                        break;
                    case UserTypes.Wholesale:
                        price = parseFloat(productSelected?.wholesalePrice.toFixed(2)); 
                        break;
                    default:
                        break;
                }
            }

            return price;
        }

        const seleccionarProducto = (producto) => {
            // Desmarca todas las filas seleccionadas
            isSelectedProduct.value = false;

            records.value.forEach(p => p.selected = false);
            // Marca la fila seleccionada
            if(producto.currentExistence > 0){
                productSelected.value = producto;
                producto.selected = true;
                isSelectedProduct.value = true;

                porcentDiscont.value.push({
                    id: producto.publicPrice,
                    name: producto.publicPrice.toFixed(2)
                });

                porcentDiscont.value.push({
                    id: producto.wholesalePrice,
                    name: producto.wholesalePrice.toFixed(2)
                });

                porcentDiscont.value.push({
                    id: producto.buyerPrice,
                    name: producto.buyerPrice.toFixed(2)
                });

                if(canSalePriceFree.value){
                    validationAddProductSchema.fields.dto = Validations
                                             .number()
                                             .min(0, `Minimo desuento 0`)
                                             .max(100, `Maximo descuento 100`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                    haveDiscont.value = false;
                }else{
                    if(producto.maximunDiscount > 0 && !canSalePriceFree.value){
                        validationAddProductSchema.fields.dto = Validations
                                             .number()
                                             .min(producto.minimunDiscount, `Minimo desuento ${producto.minimunDiscount}`)
                                             .max(producto.maximunDiscount, `Maximo descuento ${producto.maximunDiscount}`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                            haveDiscont.value = false;
                        }

                        if(producto.maximunDiscount > 0){
                            haveDiscont.value = false;
                        }
                }

                // if(producto.minimunDiscount > 0 ){
                //     haveDiscont.value = false;
                // }

                validationAddProductSchema.fields.amount = Validations
                                                    .number()
                                                    .required()
                                                    .min(1, 'Ingrese una cantidad minima de 1')
                                                    .max(producto.stock, `Ingrese una cantidad minima de ${producto.stock}`)
                                                    .typeError('Ingrese una cantidad valida')
                                                    .label("amount");

                if(!producto.isInventory){
                    validationAddProductSchema.fields.amount = Validations
                                            .number()
                                            .required()
                                            .min(1, 'Ingrese una cantidad minima de 1')
                                            .typeError('Ingrese una cantidad valida')
                                            .label("amount");
                }
                
                const idClient = moduleNewSale.idClientSelected;
                const client = clientsCombo.value.find(ir => ir.id === idClient);

                const priceDefault = calculatePriceByInput(client?.customerType);

                formAddProductToSale.value.setValues({
                        price: canSalePriceFree.value || producto.isInventory ? priceDefault : client?.customerType,
                        dto: producto.minimunDiscount
                });
            }
          
        }
        
        const handleClickPage = (page) => {
            currentPage.value = page;

            searchForm(dataSearch.value);
        }

        // const taxesInfo = computed(() => moduleTaxes.getTaxeInfo);

        const numRecords = computed(() => {
            return numItemsPaginator.value;
        });

        const recordsPerPage = computed(() => {
            return itemsPerPage.value;
        });

        const currentSelectedPage = computed(() => {
            return currentPage.value;
        });

        const clientsCombo = computed(() => moduleNewSale.getClientOptions);
        const canSalePriceFree = computed(() => store.getters['canSalePriceFree']); 

        return {
            labels
            , canSalePriceFree
            , productSelected
            , handlesubfamilia
            , subfamilyCombo
            , clientsCombo
            , handleSearchWarehouse
            , headers
            , records
            , validationAddProductSchema
            , isSelectedProduct
            , validationShearchSchema
            , expandCollapse
            , numRecords
            , numItemsPaginator
            , itemsPerPage
            , recordsPerPage
            , currentPage
            , currentSelectedPage
            // , taxesInfo
            , porcentDiscont
            , haveDiscont
            , UserTypes
            , handleClickPage
            , seleccionarProducto
            , submitForm
            , searchForm
            , calculatePercentage
            , sucessAddProduct
            , handleFamily
            , familyCombo
            , warehouseCombo
            , formAddProductToSale
            , calculatePriceByInput
        }
    }
})
