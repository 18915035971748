import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ModuleMaster, {
      requirePaginator: false,
      labels: _ctx.labels,
      numItemsPaginator: _ctx.totalRecords,
      itemsPerPage: _ctx.sizePage,
      requireSearchField: false,
      requireAddButton: false,
      fnCallbackCrudAction: _ctx.handleCrudAction,
      useExtraButtons: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Datatable, {
          headers: _ctx.headers,
          requireActions: false,
          requireStatusCol: false
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
              return (_openBlock(), _createBlock(_component_Datarow, {
                key: row.ID,
                dataRow: row,
                showCrudActions: false
              }, null, 8, ["dataRow"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["headers"])
      ]),
      _: 1
    }, 8, ["labels", "numItemsPaginator", "itemsPerPage", "fnCallbackCrudAction"])
  ]))
}