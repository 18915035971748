import Modules from '@/store/enums/Modules';
import { ActionContext } from "vuex";
import NewSettlementState from '../modules/settlement/models/NewSettlementState';

/**
  * crea una solicitud de donde su dueño sea una liquidación
  * @param context Contexto del store dentro del modulo
  * @param form datos del formulario
  * @returns la petición con los datos para una nueva nota
  */
 function createRequest<RequestType>(
        context: ActionContext<ThisType<any>, any>
        , form: any) {

    const module = (context.rootState[Modules.NewSettlement] as NewSettlementState)
    
    const idSettlement = module ? module['settlementIdSelected'] : null;
    return {
        liquidationId: idSettlement
        , ...form 
    } as RequestType
 }
 export { createRequest }