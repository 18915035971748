
import { defineComponent, onMounted } from "vue"
// import SaleInformation from "./SalesInformation.vue"
import Table from "./TableSalesInformation.vue"
import TableFacturas from "./TableSalesFacturas.vue"
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/plugins/i18n";

export default defineComponent({
    components:{
         TableFacturas
        ,Table
    },
    setup(){
        //  onMounted(() => {
        //     setCurrentPageBreadcrumbs( translate('BREADCRUMB.SALESDETAIL', { folio: "123545" }), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALES'), to: "sales" }]);
        //   });
    }
})
