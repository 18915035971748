export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
        },
        "INPUT_UNIT_PRICE": {
          "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio unitario"])},
          "PLACEHOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una cantidad"])}
        }
      }
    }
  })
}
