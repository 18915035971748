import OptionSelect from '@/models/shared/OptionSelect';
import InfoTaxes from '@/models/Taxes/InfoTaxes';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GeneralService from '../interfaces/GeneralService';
import MessageService from '../interfaces/MessageService';
import SwalMessageService from '../SwalMessageService';

export default class TaxesService implements GeneralService{
    apiController = "/Taxes";
    messageService: MessageService = new SwalMessageService();

    
    async getInfoTaxes(id: string): Promise<AxiosResponse<InfoTaxes>> {
        return  ApiService.get<InfoTaxes[]>(
            this.apiController, 
            id
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }
}