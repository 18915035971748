import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from './Mutations';
import TaxesState from '../../models/TaxesState';
import InfoTaxes from '@/models/Taxes/InfoTaxes';
import TaxesService from '@/core/services/taxes/TaxesService';


@Module({dynamic: true, store, namespaced: true, name: Modules.TaxesModule})
export default class TaxesModule extends VuexModule implements TaxesState {
    Taxe = taxesDefaultValue;
    loading = false;
    
    get getTaxeInfo(): InfoTaxes {
        return this.Taxe;
    }

    @Mutation
    [Mutations.SET_INFO_TAXE](value: InfoTaxes){
        this.Taxe = value;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Action({ commit: Mutations.SET_INFO_TAXE })
    async [Actions.SEARCH_INFO_TAXES](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getInfoTaxes(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }

}

const service =  new TaxesService();

const taxesDefaultValue = {
    id: "",
    code: "",
    name: "",
    value: 0,
    isActive: true
    
} as InfoTaxes;
